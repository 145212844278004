<template>
    <div>
        <div class="my-5 text-center offset-2 col-8">
            <img src="@/assets/lock.svg" class="img-fluid">
        </div>
        <div class="text-center">
            <h2>Jetzt Anmelden</h2>
            <p>oder <a @click="changeComponent('RegisterComponent')" class="text-vue2" role="button">registieren Sie sich</a></p>
        </div>
        <div class="alert alert-danger col-md-8 offset-2" v-if="error">
            {{ errorDisplayText }}
        </div>
        <Form @submit="submitData" :validation-schema="schema" v-slot="{ errors }"> <!-- Daten werden mit unten erstellten Schema validiert und es wird ein Object über v-slots (z.B slotProps) zur Verfügung gestellt, aber wir nutzen Destructuring ({}) und geben nur das Errors-Object aus -->
            <div class="form-row">
                <div class="form-group col-8 offset-2">
                    <label for="email"><strong>Email-Adresse</strong></label>
                    <Field as="input" name="email" type="email" id="email" class="form-control" /> <!-- <Field as="input" /> ist die vee-validate Definition eines Inputs und über name="" kann es ausgelesen werden -->
                    <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-8 offset-2">
                    <label for="password"><strong>Passwort</strong></label>
                    <Field as="input" name="password" type="password" id="password" class="form-control" />
                    <small class="text-danger" v-if="errors.password">{{ errors.password }}</small>
                </div>
            </div>
            <div class="form-row mt-3">
                <div class="form-group col-8 offset-2">
                    <div class="d-grid">
                        <button class="btn bg-vue">
                            <span v-if="!isLoading">Anmelden</span>
                            <span v-else class="spinner-border spinner-border-sm"></span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Form, Field } from 'vee-validate'; // Destructuring-Schreibweise, um nur bestimmte Teile des Pakets zu importieren
    import * as yup from 'yup';

    export default {
        name: 'LoginComponent',
        components: {
            Form,
            Field
        },
        emits: {
            'change-component': (payload) => {
                if (payload.componentName !== 'RegisterComponent') {
                    return false;
                }
                return true
            }
        },
        data() {
            // Schema für Object festlegen.. gibt es auch für string usw.
            const schema = yup.object().shape({
                email: yup.string().required('Email-Adresse ist ein Pflichtfeld').trim().email('Das ist keine gültige Email-Adresse'), // Schlüssel muss dem name-Attribute aus template entsprechen
                password: yup.string().required('Passwort ist ein Pflichtfeld')
            });
            return {
                schema,
                error: '',
                isLoading: false
            }
        },
        methods: {
            submitData(values) {
                this.isLoading = true;
                this.error = '';

                this.$store
                    .dispatch(
                        'signin',  // 1. Argument ist der Name der aufzurufenden
                        {  // 2. argument ist der Payload -> Objekt mit Key:Value
                            email: values.email,
                            password: values.password
                        }
                    )
                    .then(() => {
                        this.isLoading = false;
                        //console.log('Login erfolgreich');
                        // Weiterleitung zum internen Bereich
                        //this.$router.push('/shop'); // Kurzschreibweise
                        this.$router.push({
                            path: '/shop'
                        })
                        //this.changeComponent('LoginComponent');
                    })
                    .catch((error) => {  // Wir holen das von axios zurückgegebene promise (errorMessage)
                        this.isLoading = false;
                        this.error = error.message; // die in errorDisplayText aufgerufene Funktion include funktioniert nur mit einem String, weswegen nicht das error-Object genutzt werden kann, sonder explizit auf message zugegriffen werden muss
                    })
            },
            changeComponent(componentName) {
                this.$emit('change-component', { componentName } );
            }
        },
        computed: {
            errorDisplayText() {
                if (this.error) {
                    if (this.error.includes('EMAIL_NOT_FOUND')) {
                        return 'Für Email-Adresse wurde kein:e Nutzer:in gefunden';
                    } else if (this.error.includes('INVALID_PASSWORD')) {
                        return 'Das Passwort ist nicht gültig';
                    }
                    return 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es noch einmal.'
                }
                return '';
            }
        }
    }
</script>

<style scoped>

</style>