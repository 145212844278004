// 1. Version einer möglichen Modularisierung
// z.B. für kleine Module wie Auth

import { FIREBASE_API_KEY } from '../../../config/firebase';
import axios from 'axios';

let timer;

const state = {
    userId: null,
    token: null,
    autoRedirect: false
}

const mutations = {
    setUser(state, payload) { // den mutations wird das state-Objekt direkt übergegeben, weil es später gut ist, den Code auszulagen und dann nicht mehr mit this gearbeitet werden kann
        state.userId = payload.userId;
        state.token = payload.token;
    },
    setAutoRedirect(state, payload) {
        state.autoRedirect = payload.autoRedirect;
    }
}

const actions = {
    auth(context, payload) { // context enthält viele nützliche Eigenschaften und Funktionen, wie commit()
        let url = '';
        if (payload.mode == 'signin') {
            url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${FIREBASE_API_KEY}`;
        } else if (payload.mode == 'signup') {
            url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${FIREBASE_API_KEY}`;
        } else {
            return;
        }

        const authDO = {
            email: payload.email,
            password: payload.password,
            returnSecureToken: true
        }

        // Axios liefert ein promise zurück, was über return an die jeweilige Component zurückgegeben werden kann
        return axios
            .post(url, authDO)
            .then((response) => { // Axios arbeitet generell mit Promises und deshalb kann direkt der then Block drangehangen werden
                // Daten im LocalStorage speichern
                const expiresIn = Number(response.data.expiresIn) * 1000;
                //const expiresIn = 5 * 1000;
                const expDate = new Date().getTime() + expiresIn;

                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('userId', response.data.localId);
                localStorage.setItem('expireDate', expDate);
                localStorage.setItem('expiresIn', expiresIn);

                timer = setTimeout(() => {
                    context.dispatch('autoSignout');
                }, expiresIn);

                // mit commit() werden die Daten in mutations geändert
                context.commit(
                    'setUser', // 1. Argument ist der name der aufzurufenden Funktion
                    {   // 2. Argument sind die an die mutations zu überliefernden Daten
                        userId: response.data.localId,
                        token: response.data.idToken
                    }
                )
            })
            .catch((error) => {
                const errorMessage = new Error( error.response.data.error.message || 'UNKNOWN_ERROR' );
                throw errorMessage; // Throw to catch it later at calling component
            });
    },
    signup(context, payload) {
        const signupDO = {
            ...payload, // Spread-Operator, der Object in Object entpackt
            mode: 'signup',
        };

        return context.dispatch('auth', signupDO);
    },
    signin(context, payload) {
        const signinDO = {
            ...payload,
            mode: 'signin'
        }

        return context.dispatch('auth', signinDO);
    },
    signout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('expireDate');
        localStorage.removeItem('expiresIn');
        clearTimeout(timer);

        context.commit('setUser', {
            userId: null,
            token: null
        });
    },
    autoSignout(context) {
        if (context.getters.isAuthenticated) {
            context.commit('setAutoRedirect', {
                autoRedirect: true
            });
        }
        context.dispatch('signout');
    },
    autoSignin(context) {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const expiresIn = localStorage.getItem('expiresIn');
        const expireDate = localStorage.getItem('expireDate');
        const timeLeft = Number(expireDate) - new Date().getTime();

        if (timeLeft < 0) {
            console.log('Hier könnte noch überprüft werden, ob der Token noch gültig ist, wenn die Zeit abgelaufen ist. TimeLeft: ' + timeLeft);
        }

        // Reset AutoLogout Timer, falls der Nutzer noch im Backend angemeldet ist und der Token valide oder refreshed ist
        timer = setTimeout(() => {
            context.dispatch('autoSignout');
        }, Number(expiresIn));

        // Set User to store after refresh
        if (token && userId) {
            context.commit('setUser', {
                token: token,
                userId: userId
            }); 
        }
    }
}

const getters = {
    isAuthenticated: (state) => {
        return !!state.token // bool falls token im state gesetzt ist, dann sorgt das 1. Ausrufenzeichen dafür das der wert false wird ,2 AZ macht es true
    },
    token: (state) => state.token
}

const authModule = {
    state,
    mutations,
    actions,
    getters
}

export default authModule