import CreateProductPage from '@/pages/CreateProductPage';

const shopRoutes = [
    {
        path: '/shop',
        component:  () => import(/* webpackChunkName: 'GroupShop' */'@/pages/ShopPage.vue'), // Gleichzeitiges Laden von Components als Gruppe via dynamic import, wenn webpackChunkName gleich ist
        meta: { // Über meta-Attribut global Eigenschaften an Navigation Guard gegeben werden
            requiresAuth: true
        }
    },
    // Alternative Layout-Strategie, die vue-router zur Verfügung stellt <router-view> (anstatt Slots)
    {
        path: '/v2-layout/shop',
        component: () => import('@/layouts/v2-layout/TheShopLayout2.vue'), // in der Component kann <router-view>-Tag gesetzt werden, der dann vom children ersetzt wird
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                component: () => import('@/pages/v2-layout/ShopPage2.vue')
            },
            {
                path: 'create/product', // Bei Children-path keinen Backslash vorn
                component: () => import('@/pages/v2-layout/CreateProductPage2.vue')
            }
        ]
    },
    {
        path: '/shop/create/product',
        component: CreateProductPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        // Named Routes
        path: '/shop/view/product/:id', // Dynamischer Inhalt in router mit : initiiert und nur nennen es id,
        name: 'ViewProduct', // 
        component: () => import(/* webpackChunkName: 'GroupShop' */'@/pages/ViewProductPage.vue'), // Gleichzeitiges Laden von Components als Gruppe via dynamic import, wenn webpackChunkName gleich ist
        props: true, // dieses Attribut sorgt dafür, dass die dynamischen Parameter aus URL an props der Component gebunden werden
        meta: {
            requiresAuth: true,
            enterTransition: 'rubberBand'
        }
    }
]

export default shopRoutes;