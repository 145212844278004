export const getters = {
    cartItems: (state) => state.cartItems,
    // (state, getters, rootState, rootGetters)
    cartTotal: (state, getters, __,rootGetters) => {
        //console.log(state);
        //const cartItems = state.cartItems --> reguläre Vorgehensweise, aber Beispiel für weitere Arguments für getters-functions
        const cartItems = getters.cartItems;
        return cartItems.reduce((sum, cartItem) => { // Return des Getters
            //console.log(i)

            //console.log(cartItem.productId.productId);
            const product = rootGetters.getSingleProduct(cartItem.productId);
            return sum + parseFloat(product.price); // Return der reduce-function
        }, 0); // Startwert ist 0
    }
}