<!--
In diesem Projekt werden folgende Themen behandelt

Stichwort - Themen
------------------
Ordnerstruktur - Layout-, Page-, Components, Store, Router
Layouts - Slot-Layout Stategie & Router-View Stategie
Formulare - Formulare mit VeeValidate & Yup
Backend & Datenbank - Dauerhafte Speicherung der Daten mit Firebase
Vue-Router - Routing
Vuex - State Management
Vue-Devtools - Debugging
-->

<template>
    <!-- Dynamische Einbindung über vue-router Component.. hier wird dann über den vue-router die entsprechende Component hinterlegt -->
    <router-view></router-view><!-- Component ist verfügbar durch app.use(router) in main.js -->
    <!--<router-view name="alternative"></router-view>--><!-- Named View -->

    <!-- Lange Form dieser globalen Transition, gibt die Möglichkeit die Component in eine Transition zu packen -->
    <!-- Über slot-props gibt uns der router z.B. die Component zurück -->
    <!-- transition name="fade" nicht aktiv durch enter-active-class & leave-active-class -->
    <!-- Mit Key-Attribut können wir jede Component einzigartig machen, damit die Transition innerhalb einer Component funktioniert (in blaze patch strategy) -->
    <!--
    <router-view v-slot="{ Component }">
      
      <transition 
        name="fade"
        :enter-active-class="`animate__animated animate__${animation}`"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <component :is="Component" :key="$route.path"></component>
      </transition>
    </router-view> -->
</template>

<script>
  //import TheTwoColumnsLayout from './layouts/TheTwoColumnsLayout.vue';
  //import HomePage from '@/pages/HomePage';

  export default {
    name: 'App',
    components: {
      //HomePage,
    },
    data() {
      return {
        autoRedirect: this.$store.state.auth
      }
    },
    mounted() { // Life-Cycle Hook, wenn auch im Template auf Elemente zugegriffen werden soll

    },
    created() { // im created kann nicht auf das Template zugegriffen werden, aber z.B auf store
      this.$store.dispatch('autoSignin');
    },
    computed: {
      token() {
        return this.$store.getters.token;
      },
      animation() {
        // animation ist an enter-active-class gebunden und falls über die route ein Transition-Attribut verfügbar ist nutze das, sonst fadeIn als Standard
        return this.$route.meta.enterTransition ? this.$route.meta.enterTransition : 'fadeIn'
      }
    },
    watch: {
      // Vollständige Variante für Objekte!!!
      autoRedirect: { // watch auto redirect data property
        handler(redirectValue) {
          if (redirectValue.autoRedirect === true) {
            this.$router.push('/');
          }
        },
        deep: true // Auch daten innerhalb des Objekts sollen beobachtet werden
      },
      token: { // Watch computed token property
        handler() {
          if (this.token) {
            this.$store.dispatch('fetchProducts');
          }
        },
        immediate: true // Watcher ist sofort aktiv und wird initial ausgeführt, falls der token von anfang an gesetzt ist 
      }
    }
  }
</script>

<style>
  @import "~bootstrap/dist/css/bootstrap.min.css";
  @import "~@fortawesome/fontawesome-free/css/all.min.css";
  @import "~animate.css/animate.min.css";

  .bg-vue {
    background-color: rgb(52, 73, 94);
    color: white;
  }

  .bg-vue2 {
    background-color: rgb(65, 184, 131);
    color: white;
  }

  .text-vue {
    color: rgb(52, 73, 94);
  }

  .text-vue2 {
    color: rgb(65, 184, 131);
  }

  .fade-enter-from {
    opacity: 0
  }

  .fade-enter-to {
    opacity: 1
  }

  .fade-enter-active {
    transition: all .35s 
  }
  
</style>
