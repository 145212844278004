import { createRouter, createWebHashHistory } from "vue-router";
// Auskommentierte imports im Code via dynamic imports
//import HomePage from '@/pages/HomePage' //import HomePage from '../pages/HomePage'
//import ShopPage from '@/pages/ShopPage';
//import ViewProductPage from '@/pages/ViewProductPage';
import NotFoundPage from '@/pages/NotFoundPage';
import Store from '@/store/index';
import Routes from './routes';

const router = createRouter({
    // Optionen
    history: createWebHashHistory(),
    routes: [ // routes ist ein array und für jede route gibt es ein Objekt
        ...Routes,
        // Catch all Adresse, falls URL nicht gefunden wird -> 404
        // !!! Muss am Ende aller Routes stehen !!!
        {
            path: '/:pathMatch(.*)*',
            component: NotFoundPage, // 1. Möglichkeit ein NotFoundPage-Template auszugeben (ist in diesem Fall zu bevorzugen)
            //redirect: '/' // andere Möglichkeit, wäre das redirect attribut, aber Nachtteil bei 404 ist, dass Nutzer keinen Hinweis bekommt
        }
    ]
});

// Globaler Navigation Guard
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !Store.getters.isAuthenticated) {
        next('/');
    } else {
        next(); // !!! Muss ohne Argument aufgerufen werden, leitet sonst infinity loop
    }
});

export default router;

// createWebHistory -> www.shop.de/auth/login
// createWebHashHistory -> www.shop.de/index.html/#/auth/login --> alles nach dem # wird vom Server ignoriert