<template>
    <div>
        <div class="my-5 text-center offset-2 col-8">
            <img src="@/assets/welcome.svg" class="img-fluid">
        </div>
        <div class="text-center">
            <h2>Jetzt Registrieren</h2>
            <p>oder <a @click="changeComponent('LoginComponent')" class="text-vue2" role="button">melden Sie sich an</a></p>
        </div>
        <div class="alert alert-danger col-md-8 offset-2" v-if="error">
            {{ errorDisplayText }}
        </div>
        <Form @submit="submitData" :validation-schema="schema" v-slot="{ errors }"> <!-- Daten werden mit unten erstellten Schema validiert und es wird ein Object über v-slots (z.B slotProps) zur Verfügung gestellt, aber wir nutzen Destructuring ({}) und geben nur das Errors-Object aus -->
            <div class="form-row">
                <div class="form-group col-8 offset-2">
                    <label for="email"><strong>Email-Adresse</strong></label>
                    <Field as="input" name="email" type="email" id="email" class="form-control" /> <!-- <Field as="input" /> ist die vee-validate Definition eines Inputs und über name="" kann es ausgelesen werden -->
                    <small class="text-danger" v-if="errors.email">{{ errors.email }}</small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-8 offset-2">
                    <label for="password"><strong>Passwort</strong></label>
                    <Field as="input" name="password" type="password" id="password" class="form-control" />
                    <small class="text-danger" v-if="errors.password">{{ errors.password }}</small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-8 offset-2">
                    <label for="confirmPassword"><strong>Passwort wiederholen</strong></label>
                    <Field as="input" name="confirmPassword" type="password" id="confirmPassword" class="form-control" />
                    <small class="text-danger" v-if="errors.confirmPassword">{{ errors.confirmPassword }}</small>
                </div>
            </div>
            <div class="form-row mt-3">
                <div class="form-group col-8 offset-2">
                    <div class="d-grid">
                        <button class="btn bg-vue">
                            <span v-if="!isLoading">Registrieren</span>
                            <span v-else class="spinner-border spinner-border-sm"></span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Form, Field } from 'vee-validate'; // Destructuring-Schreibweise, um nur bestimmte Teile des Pakets zu importieren
    import * as yup from 'yup';
    //import { FIREBASE_API_KEY } from '../../config/firebase.js';
    //import axios from 'axios';

    export default {
        name: 'RegisterComponent',
        components: {
            Form,
            Field
        },
        emits: {
            'change-component': (payload) => {
                if (payload.componentName !== 'LoginComponent') {
                    return false;
                }
                return true
            }
        },
        data() {
            // Schema für Object festlegen.. gibt es auch für string usw.
            const schema = yup.object().shape({
                email: yup.string().required('Email-Adresse ist ein Pflichtfeld').trim().email('Das ist keine gültige Email-Adresse'), // Schlüssel muss dem name-Attribute aus template entsprechen
                password: yup.string().required('Passwort ist ein Pflichtfeld').min(6, 'Das Passwort muss mindestens 6 Zeichen lang sein'), // min-Funktion -> 2 Argumente: 1. ist die Mindestlänge des Passwortes | 2. ist die Fehlermeldung
                confirmPassword: yup.string().required('Du musst dein Passwort bestätigen').oneOf([yup.ref('password')], 'Deine Passwörter stimmen nicht überein') // oneOf-Funktion -> 2 Argumente: 1. als Array ist die Referenz mit dem das Passwort verglichen werden soll (hier das 1. Passwort) | 2. ist die Fehlermeldung
            });
            return {
                schema,
                error: '',
                isLoading: false
            }
        },
        methods: {
            submitData(values) {
                this.isLoading = true;
                this.error = '';

                this.$store
                    .dispatch(
                        'signup',  // 1. Argument ist der Name der aufzurufenden
                        {  // 2. argument ist der Payload -> Objekt mit Key:Value
                            email: values.email,
                            password: values.password
                        }
                    )
                    .then(() => {
                        //console.log(this.$store.state); // über this.$store kann in einer Component immer auf den Store zugegriffen werden
                        this.isLoading = false;
                        //this.changeComponent('LoginComponent');
                        // Statt zur Login-Component direkt zum internen Bereich, da wir mit der Registrieren direkt userId & token von Firebase erhalten
                        this.$router.push({
                            path: '/shop'
                        });
                    })
                    .catch((error) => {  // Wir holen das von axios zurückgegebene promise (errorMessage)
                        this.isLoading = false;
                        this.error = error.message; // die in errorDisplayText aufgerufene Funktion include funktioniert nur mit einem String, weswegen nicht das error-Object genutzt werden kann, sonder explizit auf message zugegriffen werden muss
                    })
            },
            changeComponent(componentName) {
                this.$emit('change-component', { componentName } ); // In RegisterComponent geben wir ein Objekt als payload mit Attribut componentName zurück, in Login ist es eine Variable
            }
        },
        computed: {
            errorDisplayText() {
                if (this.error) {
                    if (String(this.error).includes('EMAIL_EXISTS')) {
                        return 'Diese Email-Adresse existiert bereits';
                    }
                    return 'Es ist ein unbekannter Fehler aufgetreten. Bitten versuchen Sie es noch einmal.'
                }
                return '';
            }
        }
    }
</script>

<style scoped>

</style>