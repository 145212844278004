import axios from 'axios';

const state = {
    products: []
};

const mutations = {
    setProducts(state, payload) {
        state.products = payload;
    },
    addProduct(state, payload) {
        state.products.push(payload);
    }
};

const actions = {
    fetchProducts(context) { // Wird über dispatch vom token watcher in App.vue ausgelöst
        const token = context.rootState.auth.token;

        axios
            .get(`https://grodonkey-vue-shop-default-rtdb.europe-west1.firebasedatabase.app/products.json?auth=${token}`)
            .then((response) => {

                // Meine Länger JS Basierte Schreibweise
                /*
                const productsDataObject = response.data;
                const keys = Object.keys(productsDataObject);

                for (let i = 0; i < keys.length; i++) {
                    const productDataObject = {
                        id: keys[i],
                        title: productsDataObject[keys[i]].title,
                        desc: productsDataObject[keys[i]].desc,
                        price: productsDataObject[keys[i]].price
                    }

                    context.commit('addProduct', productDataObject);
                }*/

                const productsDataObject = [];
                for (const id in response.data) {
                    productsDataObject.push({
                        id: id,
                        ...response.data[id] // Restliche werte hole ich mit key:value vom object vomn der jeweiligen Id
                    });
                }

                context.commit('setProducts', productsDataObject);

            })
            .catch((error) => {
                //throw new Error(error);
                console.log(error);
            })
    }, 
    storeProduct(context, payload) {
        const token = context.rootState.auth.token;
        const productItem = {
            title: payload.title,
            desc: payload.desc,
            price: payload.price
        };

        axios
            .post(`https://grodonkey-vue-shop-default-rtdb.europe-west1.firebasedatabase.app/products.json?auth=${token}`, productItem)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                throw new Error(error); // hier einfach gemacht, denn den Fehler an Component zurückgeben, sodass die sich darum kümmert 
            })
    }
};

const getters = {
    products: (state) => state.products,
    //getterName: (state) => () => {} // Syntax eines getters, der einen spezifischen Wert übergeben bekommt
    getSingleProduct: (state) => (id) => state.products.find((product) => product.id === id) // return kann weggelassen werden, weil wir alles in einer zeile schreibe
};

const shopModule = {
    state,
    mutations,
    actions,
    getters
};

export default shopModule;