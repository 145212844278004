<template>
    <TheTwoColumnsLayout 
        :leftColumnClass="'col-md-8 bg-vue'"
        :rightColumnClass="'col-md-4'"
        :fullSize="true"
    >
        <template #leftCol>
            <div class="mt-5 text-center">
                <div class="display-1 my-5">
                    Der Shop
                </div>
                <div class="display-4 my-5">
                    Erhalten Sie Zugriff auf exklusive Artikel
                </div>
                <div class="my-5 offset-4 col-4">
                    <img src="@/assets/shopping.svg" class="img-fluid">
                </div>
            </div>
        </template>
        <template #rightCol>
            <!-- Dynamische Bindung der Components, je nachdem welcher Name uns vom Kind-Element emittiert wird -->
            <transition
                enter-active-class="animate__animated animate__fadeIn" 
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in" 
            >
                <component 
                    :is="componentName" 
                    @change-component="changeComponent">
                </component>
            </transition>
        </template>
    </TheTwoColumnsLayout>
</template>

<script>
    // import TheTwoColumnsLayout from '../layouts/TheTwoColumnsLayout.vue'; (Normaler Import) --> webpack-Alternative import TheTwoColumnsLayout from '@/layouts/TheTwoColumnsLayout'; 
    // @import, da wir das in der vue.config.js absolute Pfade festgelegt haben
    import TheTwoColumnsLayout from '@layouts/TheTwoColumnsLayout'; 
    import RegisterComponent from '@components/auth/RegisterComponent';
    import LoginComponent from '@components/auth/LoginComponent';

    export default {
        name: 'HomePage',
        components: {
            TheTwoColumnsLayout,
            RegisterComponent,
            LoginComponent
        },
        data() {
            return {
                componentName: 'RegisterComponent'
            }
        },
        methods: {
            changeComponent(payload) { // Payload enthält die emittierten Daten von der Kind-Component, also den Name der anzuzeigen Component
                this.componentName = payload.componentName;
            }
        }
    }
</script>

<style scoped>
</style>