import { createApp } from 'vue';
import App from './App.vue';
import Store from './store'; // index.js wird automatisch geladen, wenn sie existiert
import Router from './router';

const app = createApp(App);
app.use(Store);
app.use(Router);
app.mount('#app');

// Fluid Interfaces
/*
createApp(App)
    .use(Store)
    .mout('#app');
*/
