<template>
    <div>
        <Navbar />
        <!-- Navbar -> wird in Component ausgelagert -->
        <main>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Transitions werden nicht beim Erstellen einer Component ausgeführt, aber mit dem Attribut appear funktioniert das -->
                        <!-- Mit Key-Attribut können wir jede Component einzigartig machen, damit die Transition innerhalb einer Component funktioniert (in blaze patch strategy) -->
                        <transition
                            enter-active-class="animate__animated animate__fadeIn"
                            leave-active-class="animate__animated animate__fadeOut"
                            mode="out-in"
                            appear
                            :key="$route.path"
                        >
                            <slot><!-- Unnamed slot -> befüllbar über <template #default /> -->
                                <h1>Hauptbereich</h1> <!-- Default, aber eigentlich wird es von außen gefüllt über template in der aufzurufenden Component -->
                            </slot>
                        </transition>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Navbar from '@/components/NavbarComponent';
    export default {
        name: 'TheShopLayout',
        components: {
            Navbar
        }
    }
</script>

<style scoped>

</style>