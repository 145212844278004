<template>
    <TheShopLayout>
        <template #default>
            <div class="row">
                <div class="col-12">
                    <h1 class="mt-4">
                        Neuer Artikel
                        <button class="btn btn-lg bg-vue float-end" @click="createProduct">Speichern</button>
                    </h1>
                    <div class="card mt-4">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <img src="https://dummyimage.com/600x400/34495e/fff" alt="Dummy Image" class="card-img">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <div class="row align-items-center mb-3">
                                        <div class="col-9">
                                            <h5 class="card-title mb-0">{{ product.title }}</h5>
                                        </div>
                                        <div class="col-3">
                                            <div class="d-grid">
                                                <button class="btn bg-vue2 float-end">{{ product.price }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">{{ product.desc }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </TheShopLayout>
</template>

<script>
    import TheShopLayout from '@/layouts/TheShopLayout.vue';
    export default {
        name: 'CreateProductPage',
        components: {
            TheShopLayout
        },
        data() {
            return {
                product: {
                    title: 'Product ' + Math.ceil(Math.random() * 10000),
                    desc: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat autem, praesentium tenetur velit accusantium nemo ipsa quod delectus neque, ex qui maxime nihil odit earum molestiae distinctio quas quam sint.`,
                    price: Math.ceil(Math.random() * 100) + ' €'
                }
            }
        },
        methods: {
            createProduct() {
                this.$store.dispatch('storeProduct', this.product);
            }
        }
    }
</script>

<style scoped>

</style>