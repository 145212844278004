import Store from '@/store/index';
import HomePage from '@/pages/HomePage.vue';

const appRoutes = [
    {
        path: '/',
        alias: '/home', //  Startpage ist unter domain und domain/home erreichbar
        components: {
            default: () => import(/* webpackChunkName: 'HomePageComponent' */'@/pages/HomePage.vue'), // Dynamic import, arrow-function, die import ausführt
            alternative: HomePage // alternative ist die reference zum named view (name="alternative")
        },
        // (Lokaler) Navigation Guard für einzelne Route 
        // Ähnlich wie Lifecycle Hooks
        beforeEnter: (to, from, next) => { // 1/3 Guards
            //console.log(to);
            //console.log(from);
            //console.log(next);
            if (Store.getters.isAuthenticated) { // hier implizite Schreibweise, damit isAutheticated() das state-Object automatisch übergeben bekommt
                next('/shop');
            } else {
                next(); // ohne Argument, deshalb geht das Routing weiter, als wäre nie etwas passiert
            }
        }
    }
]

export default appRoutes;