// Simpler JS Ansatz zur Modularisierung des routers

import appRoutes from './app-routes';
import shopRoutes from './shop-routes';

const routes = [
    ...appRoutes,
    ...shopRoutes
]

export default routes;