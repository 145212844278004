<template>
    <nav class="navbar navbar-expand-lg bg-vue navbar-dark">
        <div class="container">
            <router-link to="/" class="navbar-brand">Der Shop</router-link>
            <ul class="navbar-nav me-auto">
                <li class="nav-item active">
                    <router-link class="nav-link" to="/shop">Startseite</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/shop/create/product">Neuer Artikel</router-link>
                </li>
            </ul>
            <button class="btn bg-vue me-3">
                <i class="fas fa-shopping-card"></i> Warenkorb ({{ cartSum }} <!-- {{ cartTotall }}--> €)  
            </button>
            <button class="btn bg-vue2" @click="signout">
                <i class="fas fa-sign-out-alt"></i> Logout 
            </button>
        </div>
    </nav>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'NavbarComponent',
        // Vuex Map Helper Funkcion
        /*computed: {
            cartTotal() {
                return this.$store.getters.cartTotal
            }
        },*/
        // Da hier ein ganzes computed-Objekt zurückgegeben wird, kann mit der Hilfsfunktion keine eigenen computed properties mehr geschrieben werden
        //computed: mapGetters(['cartTotal']),
        // aber der Spread-Operator schafft abhilfe
        computed: {
            //...mapGetters(['cartTotal']), // ohne spread-operator würde das nicht klappen
            // Falls getter und computed property verschiedene Namen haben, dann statt Array- die Objektschreibweise
            ...mapGetters({
                cartSum: 'cartTotal'
            })
        },
        methods: {
            async signout() {
                try {
                    await this.$store.dispatch('signout'); // innerhalb der async-Function kann diese Funktion abgewartet werden
                    this.$router.push('/'); // Nachfolgende Funktionen werden erst NACH der await Funktion ausgeführt
                } catch(error) {
                    console.log(error);
                }
                // Hier asynchroner Code, weil liegt in der Natur von JS
                // besser erst mit der Ausführung router function zu warten, bis der dispatch abgeschlossen ist, im Logout könnte ja etwas schiefgehen
                
            }
        }
    }
</script>

<style scoped>

</style>